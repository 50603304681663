import { makeUId } from "@k2/utils";
import React from "react";
import {
  RadioGroupWrapper,
  RadioInput,
  RadioWrapper,
  Input,
  Fill,
  Label,
} from "./radio.style";
import {
  RadioGroupProps,
  RadioProps,
  RadioGroupContextProps,
} from "./radio.interface";

const RadioGroupContext = React.createContext<RadioGroupContextProps | null>(
  null,
);
const RadioGroupContextProvider = RadioGroupContext.Provider;

export const Radio = (props: RadioProps) => {
  const { value, checked, name, children, id } = props;
  const radioId = id || makeUId();
  const context = React.useContext(RadioGroupContext);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (props.onChange) {
      props.onChange(e);
    }

    if (context?.onChange) {
      context.onChange(e);
    }
  };

  return (
    <RadioWrapper>
      <RadioInput>
        <Input
          type="radio"
          id={radioId}
          onChange={onChange}
          name={name}
          value={value}
          checked={checked}
          aria-checked={checked}
        />
        <Fill />
      </RadioInput>
      <Label htmlFor={radioId}>{children}</Label>
    </RadioWrapper>
  );
};
export const RadioGroup = (props: RadioGroupProps) => {
  const { value, defaultValue, children, disabled, name } = props;
  const [rgValue, setRGValue] = React.useState<any>(defaultValue || value);

  const onRadioChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    const lastValue = rgValue;
    const val = ev.target.value;
    if (!("value" in props)) {
      setRGValue(val);
    }
    const { onChange } = props;
    if (onChange && val !== lastValue) {
      onChange(ev);
    }
  };

  return (
    <RadioGroupWrapper>
      <RadioGroupContextProvider
        value={{
          onChange: onRadioChange,
          value,
          disabled: disabled,
          name: name,
        }}
      >
        {children}
      </RadioGroupContextProvider>
    </RadioGroupWrapper>
  );
};
