import * as React from "react";
import { Layout } from "react-grid-layout";
import { Breakpoints } from "./types";

export const defaultNoOfCols = 3;
export const generateLayout = (
  children: React.ReactNode,
  noOfCols: number | Breakpoints = defaultNoOfCols,
  rowOffsets: number[] = [],
  rowHeight: number = 14,
  breakpoint?: string,
): Layout[] => {
  const colsIfBreakpoint = breakpoint
    ? noOfCols[breakpoint] || noOfCols
    : noOfCols;
  const gridSize = colsIfBreakpoint;
  let grid = initializeGrid(children, colsIfBreakpoint, breakpoint);
  const colWidth = gridSize / colsIfBreakpoint;
  const layout = React.Children.map(
    children,
    (child: React.ReactElement<any>, i) => {
      let { props: { widthUnits = 1, heightUnits = 1 } = {} } = child;
      if (breakpoint) {
        widthUnits =
          typeof widthUnits === "object"
            ? widthUnits[breakpoint] || 1
            : widthUnits;
        heightUnits =
          typeof heightUnits === "object"
            ? heightUnits[breakpoint] || 1
            : heightUnits;
      }
      const retObj = allocateSpaceInGrid(grid, widthUnits, heightUnits);
      const { x, y, updatedGrid } = retObj;
      grid = updatedGrid;
      return {
        x: x * colWidth,
        y: rowOffsets[y] ? y * rowHeight + rowOffsets[y] : y * rowHeight,
        w: widthUnits * colWidth,
        h: heightUnits,
        i: i.toString(),
      };
    },
  );
  return layout || [];
};

const initializeGrid = (
  children: React.ReactNode,
  noOfCols: number = 3,
  breakpoint?: string,
): number[][] => {
  // TODO: @Bilal kindly review the code here, i replaced the previous implementation as there was issue with that
  type ChildProps = {
    widthUnits: number;
    heightUnits: number;
  };

  let maxHeightUnits = 1;
  const childrenUnits = React.Children.map(
    children || [],
    (child: React.ReactElement<ChildProps>) => {
      let {
        props: { widthUnits = 1, heightUnits = 1 },
      } = child;
      if (breakpoint) {
        widthUnits =
          typeof widthUnits === "object"
            ? widthUnits[breakpoint] || 1
            : widthUnits;
        heightUnits =
          typeof heightUnits === "object"
            ? heightUnits[breakpoint] || 1
            : heightUnits;
      }
      if (heightUnits > maxHeightUnits) {
        maxHeightUnits = heightUnits;
      }
      return widthUnits * heightUnits;
    },
  );

  const noOfReqdUnits: number = childrenUnits.reduce((totalReqdUnits, unit) => {
    return totalReqdUnits + unit;
  }, 2);

  const noOfRows = Math.ceil(noOfReqdUnits / noOfCols);

  const grid = initZeros([
    maxHeightUnits > noOfRows ? maxHeightUnits : noOfRows,
    noOfCols,
  ]);
  return grid;
};

const allocateSpaceInGrid = (
  passedGrid: number[][],
  reqdWidthUnits: number,
  reqdHeightUnits: number,
): {
  x: number;
  y: number;
  updatedGrid: number[][];
} => {
  const grid = passedGrid.map(arr => arr.slice()); // immuting
  // handling error input
  if (reqdHeightUnits < 1) reqdHeightUnits = 1;
  else reqdHeightUnits = Math.trunc(reqdHeightUnits);

  if (reqdWidthUnits < 1) reqdWidthUnits = 1;
  else reqdWidthUnits = Math.trunc(reqdWidthUnits);

  // case 1: both width and height units are 1 (unit redget)
  if (reqdWidthUnits === 1 && reqdHeightUnits === 1) {
    for (let y = 0; y < grid.length; y += 1) {
      for (let x = 0; x < grid[y].length; x += 1) {
        if (grid[y][x] === 0) {
          grid[y][x] = 1;
          return { updatedGrid: grid, x, y };
        }
      }
    }
  }
  // case 2: widthUnit is greater than one and heightUnit is one (rectangular/horizontal redget)
  // TODO: Assuming reqdWidthUnits is always less than or equal to noOfCols, make it more flexible
  if (reqdWidthUnits > 1 && reqdHeightUnits === 1) {
    for (let y = 0; y < grid.length; y += 1) {
      for (let x = 0; x < grid[y].length; x += 1) {
        if (grid[y][x] === 0) {
          let found = true;
          for (let i = 0; i < reqdWidthUnits; i += 1) {
            if (grid[y][x + i] !== 0) found = false;
          }
          if (found) {
            for (let i = 0; i < reqdWidthUnits; i += 1) {
              grid[y][x + i] = 1;
            }
            return { updatedGrid: grid, x, y };
          }
        }
      }
    }
  }
  // case 3: heightUnit is greater than one and widthUnit is one (rectangular/vertical redget)
  if (reqdWidthUnits === 1 && reqdHeightUnits > 1) {
    for (let y = 0; y < grid.length; y += 1) {
      for (let x = 0; x < grid[y].length; x += 1) {
        if (grid[y][x] === 0) {
          let found = true;
          for (let i = 0; i < reqdHeightUnits; i += 1) {
            if (grid[y + i][x] !== 0) found = false;
          }
          if (found) {
            for (let i = 0; i < reqdHeightUnits; i += 1) {
              grid[y + i][x] = 1;
            }
            return { updatedGrid: grid, x, y };
          }
        }
      }
    }
  }
  // TODO:
  // case 4: both units are greater than 1 (experimental)
  if (reqdWidthUnits > 1 && reqdHeightUnits > 1) {
    for (let y = 0; y < grid.length; y += 1) {
      for (let x = 0; x < grid[y].length; x += 1) {
        if (grid[y][x] === 0) {
          let found = true;
          for (let i = 0; i < reqdWidthUnits; i += 1) {
            for (let j = 0; j < reqdHeightUnits; j += 1) {
              if (!grid[y + j] || grid[y + j][x + i] !== 0) found = false;
            }
          }
          if (found) {
            for (let i = 0; i < reqdWidthUnits; i += 1) {
              for (let j = 0; j < reqdHeightUnits; j += 1) {
                grid[y + j][x + i] = 1;
              }
            }
            return { updatedGrid: grid, x, y };
          }
        }
      }
    }
  }
  // return same grid if no case fulfilled
  return { updatedGrid: grid, x: -1, y: -1 };
};
const initZeros = ([rows, columns]: number[]): number[][] =>
  [...Array(rows)].fill(0).map(z => [...Array(columns)].fill(0));
