// libs
import * as React from "react";
import { withTheme } from "styled-components";
import { LoadingIcon } from "@k2/utils";
// src

import { Layout, Layouts } from "react-grid-layout";
import isEqual from "react-fast-compare";
import GridLayoutInner from "./GridLayoutInner";
import { generateLayout } from "./generateLayout";
import {
  GridLayoutProps,
  GridLayoutPropsWithTheme,
  Breakpoints,
  GridGap,
} from "./types";

type State = {
  layout: null | Layouts | Layout[];
};

class Grid extends React.Component<GridLayoutProps, State> {
  grid = null;

  constructor(props: GridLayoutProps) {
    super(props);
    this.state = {
      layout: null,
    };
    this.updateGrid = this.updateGrid.bind(this);
  }
  verifyComponentAndChildrenProps(
    breakpoints?: Breakpoints,
    noOfCols?: Breakpoints | number,
    gridGap?: GridGap,
    children?: React.ReactNode,
  ): boolean {
    if (!breakpoints) {
      if (noOfCols && typeof noOfCols === "object") {
        console.error(
          "Expecting noOfCols to be number instead got object",
          noOfCols,
        );
        return false;
      }
      if (
        React.Children.toArray(children || []).some(
          ({ props: { widthUnits, heightUnits } }: React.Component<any>) =>
            (widthUnits && typeof widthUnits === "object") ||
            (heightUnits && typeof heightUnits === "object"),
        )
      ) {
        console.error(
          "Expecting unit props of children to be number instead got object",
          children,
        );
        return false;
      }
      if (typeof gridGap === "object" && !Array.isArray(gridGap)) {
        console.error(
          "Expecting grid gap to be [number,number], instead got object",
          gridGap,
        );
        return false;
      }
    }

    return true;
  }
  componentDidMount() {
    this.updateGrid();
  }

  componentDidUpdate(prevProps: GridLayoutProps) {
    if (!isEqual(prevProps, this.props)) this.updateGrid();
  }

  private updateGrid() {
    const {
      children,
      noOfCols: propsNoOfCols,
      rowOffsets: propsRowOffsets,
      rowHeight: propsRowHeight,
      gridGap: propsGridGap,
      theme: { gridLayout },
    } = this.props as GridLayoutPropsWithTheme;
    const {
      noOfCols: themeNoOfCols,
      rowHeight: themeRowHeight,
      rowOffsets: themeRowOffsets,
      breakpoints: themeBreakpoints,
      gridGap: themeGridGap,
      layout: themeLayout,
    } = gridLayout || {};
    const { breakpoints = themeBreakpoints } = this.props;
    let childrenWithProps: React.ReactNode = null;
    if (gridLayout) {
      childrenWithProps = React.Children.map(children, (child, index) => {
        const layoutItem =
          themeLayout && themeLayout[index] ? themeLayout[index] : {};
        return React.cloneElement(child, {
          heightUnits: child.props.heightUnits || layoutItem.heightUnits,
          widthUnits: child.props.widthUnits || layoutItem.widthUnits,
        });
      });
    }
    if (
      !this.verifyComponentAndChildrenProps(
        breakpoints,
        propsNoOfCols || themeNoOfCols,
        propsGridGap || themeGridGap,
        childrenWithProps || children,
      )
    ) {
      this.setState({ layout: null });
      throw new Error("Invalid Component or Child Props");
    }
    if (
      typeof breakpoints === "object" &&
      Object.keys(breakpoints).length > 0
    ) {
      const layout: Layouts = Object.keys(breakpoints).reduce((prev, curr) => {
        prev[curr] = generateLayout(
          childrenWithProps || children,
          propsNoOfCols || themeNoOfCols,
          propsRowOffsets || themeRowOffsets,
          propsRowHeight || themeRowHeight,
          curr,
        );
        return prev;
      }, {});
      this.setState(() => ({ layout }));
    } else {
      const layout: Layout[] = generateLayout(
        childrenWithProps || children,
        propsNoOfCols || themeNoOfCols,
        propsRowOffsets || themeRowOffsets,
        propsRowHeight || themeRowHeight,
      );
      this.setState(() => ({ layout }));
    }
  }

  render() {
    const {
      theme,
      gridGap: propsGridGap,
      rowHeight: propsRowHeight,
      breakpoints: propsBreakpoints,
      noOfCols: propsNoOfCols,
    } = this.props as GridLayoutPropsWithTheme;
    const {
      gridLayout: {
        gridGap: themeGridGap = undefined,
        rowHeight: themeRowHeight = undefined,
        breakpoints: themeBreakpoints = undefined,
        noOfCols: themeNoOfcols = undefined,
      } = {},
    } = theme;
    const { layout } = this.state;
    if (layout)
      return (
        <GridLayoutInner
          {...this.props}
          rowHeight={propsRowHeight || themeRowHeight}
          gridGap={propsGridGap || themeGridGap}
          breakpoints={propsBreakpoints || themeBreakpoints}
          noOfCols={propsNoOfCols || themeNoOfcols}
          layout={layout}
        />
      );
    return <LoadingIcon />;
  }
}

const GridLayout = withTheme<any>(Grid);
GridLayout.displayName = "GridLayout";
export default GridLayout;
