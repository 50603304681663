// libs
import * as React from "react";

import { LoadingIconWrapper, LoadingSpinner } from "./style";
import { LoadingIconProps } from "./types";

const LoadingIcon = (props: LoadingIconProps) => {
  const { styles, size = 20, sizeUnit = "px", color } = props;
  return (
    <LoadingIconWrapper className="loadingIconWrapper">
      <LoadingSpinner
        color={color}
        size={size}
        sizeUnit={sizeUnit}
        style={styles}
      />
    </LoadingIconWrapper>
  );
};

export default LoadingIcon;
