import React from "react";
import { GradientColors, ColorsProps, getColorByIndex } from "@k2/utils";

import { PieChartData, PieChartDataWithColor, TransformedData } from "./types";
import { RadialLabel } from "./sub-components/RadialLabel";

export const calculateTotal = (data: PieChartData[]) =>
  data.reduce((total, datum) => total + Math.max(datum.value, 0), 0);
export const transformData = (
  data: PieChartDataWithColor[],
  total: number,
  innerRadius: number,
  outerRadius: number,
): TransformedData[] => {
  let previousAngle = 0;
  return data.map((datum: PieChartDataWithColor) => {
    // adds current angle (in radians) to previous angle
    const endAngle =
      total > 0
        ? previousAngle + Math.max((datum.value / total) * 2 * Math.PI, 0)
        : 0;
    const startAngle = previousAngle;
    previousAngle = endAngle;
    return {
      ...datum,
      angle: endAngle,
      angle0: startAngle,
      radius: outerRadius,
      radius0: innerRadius,
    };
  });
};
const RADIAL_LABEL_TEXT_X_OFFSET = 3;
const RADIAN = Math.PI / 180;

export const calculateMidAngles = (data: PieChartData[], total: number) => {
  let previousAngle = 0;
  return data.map(value => {
    const angle = Math.max((value.value / total) * 360, 0);
    const midAngle = previousAngle + angle / 2;
    previousAngle += angle;
    return value.value >= 0 ? midAngle : undefined;
  });
};
export const getRadialLabelData = (
  angle: number,
  previousAngle: number,
  radius: number,
  centerX: number,
  centerY: number,
  width: number,
  labelTickWidth: number,
) => {
  const diff = angle - previousAngle;
  const offset = diff < 20 ? 15 : 8;

  // angle starts from right side so substracting 90 to align it from top
  const sin = Math.sin(RADIAN * (angle - 90));
  const cos = Math.cos(RADIAN * (angle - 90));

  const startX = centerX + radius * cos;
  const startY = centerY + radius * sin;
  // cos is positive on right side( 0-180 ) and negative on left
  const midX = centerX + (radius + offset) * cos;
  const midY = centerY + (radius + offset) * sin;

  const end = centerX + radius * labelTickWidth * (cos >= 0 ? 1 : -1);
  const endX = cos >= 0 ? Math.max(end, midX) : Math.min(end, midX);
  const endY = midY;
  // text Calculations
  const textWidth = cos >= 0 ? Math.abs(width - endX) : endX;
  const textX =
    cos >= 0
      ? endX + RADIAL_LABEL_TEXT_X_OFFSET
      : endX - (textWidth + RADIAL_LABEL_TEXT_X_OFFSET);
  const textY = endY - 12.5;
  const textAnchor: "start" | "end" = cos >= 0 ? "start" : "end";
  return {
    startX,
    startY,
    endX,
    endY,
    textX,
    textY,
    textAnchor,
    midX,
    midY,
    textWidth,
  };
};
export const getLabels = (
  data: PieChartDataWithColor[],
  total: number,
  outerRadius: number,
  width: number,
  height: number,
  labelTickWidth: number,
  className?: string,
  label?: (args: { data: PieChartData; total: number }) => JSX.Element,
) => {
  const centerX = width / 2;
  const centerY = height / 2;
  const midAngles = calculateMidAngles(data, total);
  return data.map((datum, index: number) => {
    const angle = midAngles[index] || 0;
    const previousAngle = midAngles[index - 1] || 0;
    const {
      startX,
      startY,
      midX,
      midY,
      textX,
      endX,
      endY,
      textY,
      textAnchor,
      textWidth,
    } = getRadialLabelData(
      angle,
      previousAngle,
      outerRadius,
      centerX,
      centerY,
      width,
      labelTickWidth,
    );
    return {
      x: NaN, // To remove the warning
      y: NaN, // To remove the warning
      // custom svg for radial label
      customComponent: () => {
        return (
          angle && (
            <RadialLabel
              startX={startX}
              startY={startY}
              endX={endX}
              endY={endY}
              midX={midX}
              midY={midY}
              textAnchor={textAnchor}
              textX={textX}
              textY={textY}
              data={datum}
              label={label}
              total={total}
              textWidth={textWidth}
              className={className}
            />
          )
        );
      },
    };
  });
};

export const addGradientToData = (data: PieChartData[]) => {
  return data.map(datum => ({
    ...datum,
    color: `url(#${datum.label})`,
  }));
};
export const addColorToData = (data: PieChartData[], colors: ColorsProps) => {
  return data.map((datum, index) => ({
    ...datum,
    color: colors[index % colors.length],
  }));
};

export const getGradient = (
  data: PieChartData[],
  colors: ColorsProps,
  isGradient: boolean,
): PieChartDataWithColor[] =>
  data.map((datum, index) => {
    return {
      ...datum,
      color: getColorByIndex(index, colors, isGradient),
    };
  });
export function transformLegendsData(
  series: PieChartDataWithColor[],
  colors: ColorsProps,
  isGradient: boolean,
) {
  return series.map((seriesObj, index) => {
    let colorFromArr;
    if (isGradient) {
      const gradientColors = colors as GradientColors;
      colorFromArr = colors[index]
        ? gradientColors[index].startingColor
        : "#ccc";
    } else {
      const simpleColors = colors as string[];
      colorFromArr = simpleColors[index];
    }

    return {
      name: seriesObj.label,
      color: colorFromArr || (seriesObj.color as string),
      enabled: true,
    };
  });
}

export const getFilteredData = (
  data: PieChartDataWithColor[],
  legendsObject: { [legend: string]: boolean },
) => {
  return data.filter(datum => legendsObject[datum.label]);
};
