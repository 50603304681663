import styled from "styled-components";
import { RadioPropsWithTheme } from "./radio.interface";

const getVairantColor = (props: RadioPropsWithTheme) => {
  const variant = props.variant || "primary";
  return props.theme.backgroundColors[variant];
};

export const RadioInput = styled.div<RadioPropsWithTheme>`
  margin: 5px;
  cursor: pointer;
  display: inline-flex;
  width: ${props => (props.size ? props.size : 20)}px;
  height: ${props => (props.size ? props.size : 20)}px;
  position: relative;
  label {
    margin-left: 25px;
  }
  &::before {
    content: "";
    border-radius: 100%;
    border: 1px solid ${props => props.theme.borderColors.normal};
    background: ${props => props.theme.backgroundColors.main};
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    box-sizing: border-box;
    pointer-events: none;
    z-index: 0;
  }
`;

export const Fill = styled.div`
  background: ${getVairantColor};
  width: 0;
  height: 0;
  border-radius: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: width 0.2s ease-in, height 0.2s ease-in;
  pointer-events: none;
  z-index: 1;

  &::before {
    content: "";
    opacity: 0;
    width: calc(20px - 4px);
    position: absolute;
    height: calc(20px - 4px);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid ${getVairantColor};
    border-radius: 100%;
  }
`;

export const Input = styled.input`
  opacity: 0;
  z-index: 2;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  &:checked {
    & ~ ${Fill} {
      width: calc(100% - 8px);
      height: calc(100% - 8px);
      transition: width 0.2s ease-out, height 0.2s ease-out;

      &::before {
        opacity: 1;
        transition: opacity 1s ease;
      }
    }
  }
`;

export const Label = styled.label`
  color: ${props => props.theme.textColors.normal};
`;

export const RadioWrapper = styled.div`
  width: max-content;
  display: flex;
  align-items: center;
  margin: 0 5px;
`;

export const RadioGroupWrapper = styled.div`
  width: 100%;
  display: inline-flex;
`;
