// libs
import React, {
  forwardRef,
  isValidElement,
  useCallback,
  useEffect,
  useImperativeHandle,
  useReducer,
  useRef,
} from "react";
import axios, {
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse,
  CancelTokenSource,
} from "axios";
import { useWorker } from "@koale/useworker";

import Slate from "./Slate";
import LoadingIcon from "./LoadingIcon";
import { XOR } from "./types";
import { useDeepCompareMemoize } from "./utils";

export enum Status {
  LOADING = "LOADING",
  SUCCESS = "SUCCESS",
  ERROR = "ERROR",
  INVALID_DATA = "INVALID_DATA",
  EMPTY_DATA = "EMPTY_DATA",
}

type State = {
  status: Status;
  error: null | Error;
  payload?: any;
  viewData: any;
};

type Action = {
  type: string;
  payload?: any;
  viewData?: any;
};

type ComponentStates = {
  blank?: JSX.Element | (() => JSX.Element);
  error?: (props: {
    error: boolean;
    message: string;
    refetch: Function;
  }) => JSX.Element;
  loading?: JSX.Element;
};

type Options = {
  propsValidator: (props: any) => true | Error;
  dataValidator: (props: any) => true | Error;
  blankStateValidator: (props: any) => boolean;
  defaultComponentStates?: ComponentStates;
};

type GraphQLVariable = {
  [key: string]: any;
};

type GraphQLOptions = {
  query: string;
  variables?: GraphQLVariable;
};

type PropsWithData = {
  data: object;
  componentStates?: ComponentStates;
};

type PropsWithURL = {
  url: string;
  urlOptions?: AxiosRequestConfig;
  graphQLOptions?: GraphQLOptions;
  componentStates?: ComponentStates;
  onStatusChange?: (obj: { status: Status }) => void;
  useWorker?: boolean;
  mapDataParams?: any[];
  mapData?: (urlData: any, prevData: any, deps: any) => any;
};

type Props = XOR<PropsWithURL, PropsWithData>;

export interface InjectedProps {
  data: any;
}

type Without<T, K> = Pick<T, Exclude<keyof T, K>>;

const SET_LOADING = "SET_LOADING";
const SET_VIEW_DATA = "SET_VIEW_DATA";
const SET_ERROR = "SET_ERROR";

function removeExtraProps(props: any, data: any) {
  const toRemove = new Set([
    "data",
    "componentStates",
    "mapData",
    "url",
    "urlOptions",
    "graphQLOptions",
    "mapDataParams",
  ]);
  return Object.keys(props).reduce(
    (object, key) => {
      if (toRemove.has(key)) {
        return object;
      }
      return { ...object, [key]: props[key] };
    },
    { data },
  );
}

/**
 * A function which accepts options for the HOC and it returns the HOC.
 * HOC in turn takes the wrapped component and returns a WrapperComponent which
 * depending on the requirement either returns WrappedComponent or respective State Component.
 * State Components include Loading, Blank and Error.
 *
 * @author Muhammad Ali
 * @param {Options} options
 * @returns {(React.ReactChild<{ data: any }>) => React.ReactChild<Props>}
 */
export const fetchData = (options: Options) => <P extends InjectedProps>(
  WrappedComponent: any,
) => {
  const { defaultComponentStates = {} } = options;
  function reducer(state: State, action: Action) {
    const { type, payload, viewData } = action;
    switch (type) {
      case SET_LOADING:
        return { ...state, status: Status.LOADING };
      case SET_ERROR:
        return { ...state, status: Status.ERROR, error: payload };
      case SET_VIEW_DATA: {
        const validateRsp = options.dataValidator(viewData);
        const newState = {
          viewData: null,
          payload: payload || state.payload,
          error: null,
        };
        if (validateRsp instanceof Error) {
          return {
            ...newState,
            status: Status.INVALID_DATA,
            error: validateRsp,
          };
        }
        if (options.blankStateValidator(viewData)) {
          return { ...newState, status: Status.EMPTY_DATA };
        }
        return { ...newState, status: Status.SUCCESS, viewData };
      }
      default:
        return state;
    }
  }
  type WProps = Without<P, keyof InjectedProps> & Props;
  function FetchData(props: WProps, ref: any) {
    const {
      componentStates = {},
      data,
      mapData = p => p,
      onStatusChange,
      useWorker: useWorkerProp,
      url,
      urlOptions,
      graphQLOptions,
      mapDataParams,
    } = props;
    const mapDataParamsRef = useRef<any>();
    mapDataParamsRef.current = mapDataParams;
    const prevPayload = useRef<any>();
    const [state, dispatch] = useReducer(reducer, {
      status: Status.LOADING,
      error: null,
      payload: {},
      viewData: data,
    });
    const setViewData = (viewData: any, payload?: any) => {
      dispatch({ type: SET_VIEW_DATA, payload, viewData });
    };
    const setError = (err: null | Error) => {
      dispatch({ type: SET_ERROR, payload: err });
    };

    // Fetch data and set viz data
    const axiosSource = useRef<CancelTokenSource>(axios.CancelToken.source());
    const [mapDataWorker, { kill: killWorker }] = useWorker(mapData);
    const refetch = useCallback(() => {
      if (url) {
        axiosSource.current = axios.CancelToken.source();
        dispatch({ type: SET_LOADING });
        const requestOptions: AxiosRequestConfig | undefined = graphQLOptions
          ? { ...urlOptions, method: "POST", data: graphQLOptions }
          : urlOptions;
        axios({
          url,
          ...requestOptions,
          cancelToken: axiosSource.current && axiosSource.current.token,
        })
          .then((res: AxiosResponse) => {
            const mapper = useWorkerProp ? mapDataWorker : mapData;
            return mapper(
              res.data,
              prevPayload.current,
              mapDataParamsRef.current,
            );
          })
          .then(resPayload => {
            prevPayload.current = resPayload;
            setViewData(resPayload, resPayload);
          })
          .catch((error: AxiosError) => {
            if (!axios.isCancel(error)) {
              setError(error);
            }
          });
      }
    }, useDeepCompareMemoize([url, urlOptions, graphQLOptions]));
    useEffect(() => {
      if (data) {
        setViewData(data);
      } else if (url) {
        refetch();
      } else {
        setError(Error('Either "url" or "data" is required'));
      }
      return () => {
        if (axiosSource.current) {
          axiosSource.current.cancel("Cleanup axios request");
        }
        killWorker();
      };
    }, [data, url, refetch]);

    // Validate props
    useEffect(() => {
      const validateRsp = options.propsValidator(props);
      if (validateRsp instanceof Error) {
        setError(validateRsp);
      }
    }, [props]);

    // Set ref
    useImperativeHandle(
      ref,
      () => ({
        data: data || state.payload,
        status: state.status,
        viewData: state.viewData,
        setViewData,
      }),
      [data, state],
    );

    // Bind event handler
    useEffect(() => {
      if (typeof onStatusChange === "function") {
        onStatusChange({ status: state.status });
      }
    }, [state.status]);

    switch (state.status) {
      case Status.LOADING: {
        return (
          componentStates.loading ||
          defaultComponentStates.loading || <LoadingIcon />
        );
      }

      case Status.ERROR:
      case Status.INVALID_DATA: {
        const ErrorComponent =
          componentStates.error || defaultComponentStates.error || Slate;
        return (
          <ErrorComponent
            error
            message={state.error.message}
            refetch={refetch}
          />
        );
      }

      case Status.SUCCESS: {
        return (
          <WrappedComponent {...removeExtraProps(props, state.viewData)} />
        );
      }

      // Show blank state if data not available
      default: {
        if (isValidElement(componentStates.blank)) {
          return componentStates.blank;
        }
        if (typeof componentStates.blank === "function") {
          const Blank = componentStates.blank;
          return <Blank />;
        }
        if (isValidElement(defaultComponentStates.blank)) {
          return defaultComponentStates.blank;
        }
        if (typeof defaultComponentStates.blank === "function") {
          const Blank = defaultComponentStates.blank;
          return <Blank />;
        }
        return <Slate error message="Data is empty" />;
      }
    }
  }

  return forwardRef(FetchData);
};

export default fetchData;
