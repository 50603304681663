import styled, { keyframes } from "styled-components";
import { LoadingIconProps } from "./types";

const rotate360 = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const LoadingSpinner = styled.div<LoadingIconProps>`
  animation: ${rotate360} 1s linear infinite;
  transform: translateZ(0);

  border: 2px solid
    ${({ theme, color }) => color || theme?.borderColors?.card || "#E1E1E1"};
  border-left: 2px solid
    ${({ theme, color }) =>
      color || theme?.backgroundColors?.primary || "#19CDD7"};
  background: transparent;
  width: ${({ size, sizeUnit }) => `${size}${sizeUnit}`};
  height: ${({ size, sizeUnit }) => `${size}${sizeUnit}`};
  border-radius: 50%;
`;

export const LoadingIconWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
