import * as React from "react";
import ReactGridLayout, {
  CoreProps as ReactGridLayoutCoreProps,
  ReactGridLayoutProps,
  Responsive as ResponsiveReactGridLayout,
  ResponsiveProps,
} from "react-grid-layout";
import { SizeMe } from "react-sizeme";
import { resolveElementProp, isReactComponent } from "@k2/utils";

import { RootStyled } from "./GridLayoutInner.styles";
import { GridLayoutInnerProps } from "./types";
import { defaultNoOfCols } from "./generateLayout";
/**
 * This wrapper is used to retrieve width and height passed in the hierarchy from React Grid Layout
 *
 * @param {*} props
 * @returns {React.Component}
 */
type CssPropType = React.ReactText | undefined;

type WrapperProps = {
  child: React.ReactElement<any>;
  style?: {
    width?: CssPropType;
    height?: CssPropType;
  };
  key: string;
};

const onTooltipVisible = (event: CustomEvent) => {
  const { currentTarget } = event;
  if (currentTarget instanceof HTMLElement) {
    currentTarget.style.zIndex = "1";
  }
  event.preventDefault();
};
const onTooltipHidden = (event: CustomEvent) => {
  const { currentTarget } = event;
  if (currentTarget instanceof HTMLElement) {
    currentTarget.style.zIndex = "0";
  }
  event.preventDefault();
};
const getContainerRef = (element: HTMLDivElement | null) => {
  if (element) {
    element.addEventListener("onTooltipVisible", onTooltipVisible);
    element.addEventListener("onTooltipHidden", onTooltipHidden);
  }
};
const Wrapper = (props: WrapperProps) => {
  const {
    child,
    style: { width = undefined, height = undefined } = {},
  } = props;
  const isChildComponent = isReactComponent(child);
  const childProps = isChildComponent
    ? {
        parentWidth: width,
        parentHeight: height,
        width: resolveElementProp(child, "width", width),
        height: resolveElementProp(child, "height", height),
      }
    : {};
  return (
    <div {...props} ref={getContainerRef}>
      {React.cloneElement(child, {
        ...childProps,
      })}
    </div>
  );
};
const GridLayoutInner = (props: GridLayoutInnerProps) => {
  const {
    children,
    rowHeight = 8,
    layout,
    isDraggable = true,
    isResizable = true,
    width = "100%",
    gridGap,
    draggableHandle,
    className,
    style,
    breakpoints,
    noOfCols,
  } = props;

  if (React.Children.toArray(children).length === 0) {
    console.info("GridLayout: Add Some Children for Layout to Work.");
    return null;
  }
  const gridChildren = React.Children.toArray(
    children,
  ).map((child: any, i: number) => (
    <Wrapper key={i.toString()} child={child} />
  ));

  const coreGridProps: ReactGridLayoutCoreProps = {
    rowHeight,
    isDraggable,
    isResizable,
    draggableHandle,
    useCSSTransforms: false,
    className,
    style,
  };
  const gridProps: ReactGridLayoutProps = {
    layout: Array.isArray(layout) ? layout : [],
    cols: typeof noOfCols === "number" ? noOfCols : defaultNoOfCols,
    margin: Array.isArray(gridGap) ? gridGap : undefined,
  };
  const responsiveGridProps: ResponsiveProps = {
    breakpoints,
    layouts: !Array.isArray(layout) && layout != null ? layout : {},
    className: className ? className.concat(" k2-responsive") : "k2-responsive",
    margin: gridGap,
  };
  if (breakpoints) {
    responsiveGridProps.cols = Object.keys(breakpoints).reduce(
      (cols, breakpoint) => {
        cols[breakpoint] = noOfCols?.[breakpoint] || defaultNoOfCols;
        return cols;
      },
      {},
    );
  }
  return (
    <RootStyled width={width}>
      <SizeMe refreshRate={32}>
        {({ size }: any) => {
          const { width: responsiveWidth } = size;
          return breakpoints ? (
            <ResponsiveReactGridLayout
              {...coreGridProps}
              {...responsiveGridProps}
              width={responsiveWidth}
            >
              {gridChildren}
            </ResponsiveReactGridLayout>
          ) : (
            <ReactGridLayout
              {...coreGridProps}
              {...gridProps}
              width={responsiveWidth}
            >
              {gridChildren}
            </ReactGridLayout>
          );
        }}
      </SizeMe>
    </RootStyled>
  );
};

export default GridLayoutInner;
