import * as React from "react";
import ReactTooltip from "react-tooltip";
import { GradientColors, ColorsProps } from "../theme/types";
import listOfColors from "./colors";
import isEqual from "react-fast-compare";

interface ClassNamesPrefix {
  block?: string;
  element?: string;
  modifier?: string;
  root?: string;
}

export function getRandomColor(): string {
  return listOfColors[Math.floor(Math.random() * 280)];
}

/**
 * This function generates a random text string of length `length` and returns.
 *
 * @export
 * @param {number} length
 * @returns {string} A random string ID of length characters
 */
export function makeId(length: number): string {
  let text = "";
  const possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";

  for (let i = 0; i < length; i += 1)
    text += possible.charAt(Math.floor(Math.random() * possible.length));

  return text;
}

/**
 * This method will inject a specific prop or set of props to all children (deep level)
 *
 * @param {React.ReactElement<any>} children
 * @param {*} newProps
 * @returns {(React.ReactElement<any> | any)}
 */
export const injectPropsToAllChildren = (
  children: React.ReactElement<any> | any,
  newProps: any,
): React.ReactElement<any> | any => {
  const newChildren = React.Children.toArray(children).map(
    (child: React.ReactElement<any>) => {
      const childzFurtherChildren = child.props.children
        ? injectPropsToAllChildren(child.props.children, newProps)
        : undefined;

      return childzFurtherChildren
        ? React.cloneElement(child, { ...newProps }, childzFurtherChildren)
        : React.cloneElement(child, { ...newProps });
    },
  );
  return newChildren;
};

// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.
export function debounce(
  func: () => any,
  wait: number,
  immediate: boolean = false,
) {
  let timeout: any;
  return function(this: any) {
    const context = this;
    const args = arguments;
    const later = () => {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

export function resolveComponentState(
  component: React.ReactElement<any> | any,
  props: object = {},
) {
  const componentElement =
    typeof component === "function" ? component() : component;
  return React.cloneElement(componentElement, {
    ...props,
    ...(componentElement.props || {}),
  });
}

// Returns string in 10K format and 9,854 if less than the threshold
export const convertNumberToCommaFormat = (value: number, threshold = 1000) => {
  if (isNaN(value))
    return {
      value: 0,
      unit: "",
    };

  value = Number(value);

  if (value >= threshold) {
    const thousand = 1000;
    const million = 1000000;
    const billion = 1000000000;
    const trillion = 1000000000000;
    if (value >= thousand && value < million) {
      return {
        value: (value / thousand).toFixed(1),
        unit: "k",
      };
    }
    if (value >= million && value < billion) {
      return {
        value: (value / million).toFixed(1),
        unit: "M",
      };
    }
    if (value >= billion && value < trillion) {
      return {
        value: (value / billion).toFixed(1),
        unit: "B",
      };
    }
    return {
      value: (value / trillion).toFixed(1),
      unit: "T",
    };
  }
  return {
    value: value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
    unit: "",
  };
};
// Returns string in 10K format and 9,854 if less than the threshold
export const convertBytesToDataUnit = (value: number, threshold = 1000) => {
  if (isNaN(value)) return 0;

  value = Number(value);
  const units = ["B", "KB", "MB", "GB", "TB", "PB"];
  if (value >= threshold) {
    let unit = 0;
    let remainder = value;
    while (remainder >= 1024) {
      remainder /= 1024;
      unit++;
    }
    return remainder.toFixed(1) + units[unit];
  }
  return `${value}B`;
};

export const convertNumberToCommaFormatUnitCombined = (
  value: number,
  threshold = 1000,
) => {
  const conversion = convertNumberToCommaFormat(value, threshold);
  return `${conversion.value}${conversion.unit}`;
};

/**
 * Resolves the drilldown/dropdown content to be function
 * or simple data and converts it to renderable format
 * @export
 * @param {Function | ReactComponent} content
 */
export function resolveDropdownContent(content: string | any) {
  if (typeof content === "function") return content();
  return content;
}

/**
 * Checks whether the element is a React Element.
 * @export
 * @param {React.ReactElement<any>} element
 * @returns {boolean} true if the element is a React Element
 */
export function isReactComponent(element: string | any): boolean {
  return React.isValidElement(element) && typeof element !== "string";
}

/**
 * Resolves the element's prop value by returning the existing value if present
 * or the fallback value
 * @export
 * @param {React.ReactChild} element
 * @param {string} propName the prop to resolve
 * @param {number|string} fallbackValue the width to inject
 * @param {boolean} overrideUserProp if true, always injects the passed width
 */
export function resolveElementProp(
  element: React.ReactElement<any>,
  propName: string,
  fallbackValue?: any,
  overrideUserProp: boolean = false,
): any {
  const propValue = element.props[propName];
  return overrideUserProp ? fallbackValue : propValue || fallbackValue;
}
export const throttle = (func: () => any, limit: number) => {
  let lastFunc: any;
  let lastRan: number;
  return function(this: any) {
    const context = this;
    const args = arguments;
    if (!lastRan) {
      func.apply(context, args);
      lastRan = Date.now();
    } else {
      clearTimeout(lastFunc);
      lastFunc = setTimeout(function() {
        if (Date.now() - lastRan >= limit) {
          func.apply(context, args);
          lastRan = Date.now();
        }
      }, limit - (Date.now() - lastRan));
    }
  };
};

export const shouldDisableLegend = (highChartsInstance: any) => {
  try {
    if (!highChartsInstance.visible) return true;
    const visibleSeries = highChartsInstance.chart.series.filter(
      (series: any) => series.visible,
    );
    return visibleSeries.length >= 2;
  } catch (e) {
    return true;
  }
};

/**
 * Simple object check.
 * @param item
 * @returns {boolean}
 */
export function isObject(item: any) {
  return item && typeof item === "object" && !Array.isArray(item);
}

/**
 * Deep merge two objects.
 * @param target
 * @param ...sources
 */
export function mergeDeep(target: object, ...sources: object[]): object {
  if (!sources.length) return target;
  const source = sources.shift();

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) Object.assign(target, { [key]: {} });
        mergeDeep(target[key], source[key]);
      } else {
        Object.assign(target, { [key]: source[key] });
      }
    }
  }

  return mergeDeep(target, ...sources);
}

export function setValueThroughPriority(propsValue: any, themeValue: any) {
  return propsValue !== undefined ? propsValue : themeValue;
}

export const isClient = () => {
  return (
    typeof window !== "undefined" &&
    window.CustomEvent &&
    typeof window.CustomEvent === "function" &&
    typeof document !== "undefined"
  );
};

export const showTooltip = (
  element: HTMLElement,
  tipContent: string,
  tooltipId?: string,
  tooltipOptions?: any,
) => {
  element.dataset.tip = tipContent;
  element.dataset.for = tooltipId;
  Object.keys(tooltipOptions || {}).forEach(key => {
    element.dataset[key] = tooltipOptions[key];
  });
  if (isClient()) {
    ReactTooltip.rebuild();
  }
};

/**
 * Alternate of ramda pluck.
 * @param array source array
 * @param key property to be getting.
 */
export function pluck(array: { [key: string]: any }[], key: string) {
  return array.map(o => o[key]);
}

/**
 * Type Guard to detect colors are simple or gradient
 * @param colorArr
 */
export function isGradientColorArr(
  colorArr: ColorsProps,
): colorArr is GradientColors {
  return typeof colorArr[0] === "object";
}

/**
 * Used in react-vis series to assign color from colors array.
 * Returned string format is different in different types.
 * @param index used to get color from array.
 * @param colors can be simple or gradient.
 * @param isGradient if color is gradient.
 */
export function getColorByIndex(
  index: number,
  colors: ColorsProps,
  isGradient?: boolean,
): string {
  if (colors[index] && isGradient !== undefined) {
    if (isGradient) {
      const gradientColors = colors as GradientColors;
      return `url(#${gradientColors[index].name.replace(/\s/g, "-")})`;
    }
    const simpleColors = colors as string[];
    return simpleColors[index];
  }
  return "#ccc";
}

/**
 * Transform legends data from react-vis series.
 * @param series can be any react-vis series.
 * @param colors can be gradient or simple.
 * @param isGradient
 */
export function transformLegendsData(
  series: any[],
  colors: ColorsProps,
  isGradient: boolean,
) {
  return series.map((seriesObj, index) => {
    let colorFromArr;
    if (isGradient) {
      const gradientColors = colors as GradientColors;
      colorFromArr = colors[index]
        ? gradientColors[index].startingColor
        : "#ccc";
    } else {
      const simpleColors = colors as string[];
      colorFromArr = colors[index] ? simpleColors[index] : "#ccc";
    }

    return {
      name: seriesObj.name,
      color: (seriesObj.color as string) || colorFromArr,
      enabled: true,
    };
  });
}

/**
 * This function follows BEM model for css classes.
 * It takes following parameters as an object and
 * generate classes according to BEM model.
 * @link http://getbem.com/naming/
 * @param {
 *  root: Name of app / any prefix you want to append with class,
 *  block: Block in BME model.
 *  element: Element in BME model.
 *  modifier: Modifier in BME Model.
 * }
 */
export function classNamesPrefix({
  block,
  element,
  modifier,
  root = "redgets",
}: ClassNamesPrefix): string {
  const getString = (type: string | undefined, seperator: string) => {
    if (type) {
      return `${type}${seperator}`;
    }
    return "";
  };

  const rootStr = getString(root, "-");
  const blockStr = getString(block, "__");
  const elementStr = getString(element, "--");

  if (modifier) {
    return `${rootStr}${blockStr}${elementStr}${modifier}`;
  }

  if (element) {
    return `${rootStr}${blockStr}${element}`;
  }

  if (block) {
    return `${root}-${block}`;
  }

  return root;
}

export function useDeepCompareMemoize<T>(value: T) {
  const ref = React.useRef(value);
  if (!isEqual(value, ref.current)) {
    ref.current = value;
  }
  return ref.current;
}
