import styled, { css } from "styled-components";

import { RootStyledProps, HeaderStyledProps } from "./types";

function setValueThroughPriority(themeValue: any, defaultValue: any) {
  return themeValue !== undefined ? themeValue : defaultValue;
}

const DEFAULT_BORDER_RADIUS = "4px";
const DEFAULT_BORDER_COLOR = "#e6e6e6";
const DEFAULT_BODY_PADDING = "20px";
const DEFAULT_HEADER_PADDING = "10px 20px";
const DEFAULT_BOX_SHADOW_COLOR = "rgba(178, 178, 178, 0.29)";
const DEFAULT_HAS_BOX_SHADOW = true;
const DEFAULT_HAS_HEADER_BORDER = true;
const DEFAULT_HAS_BORDER = true;

export const RootStyled = styled.div<RootStyledProps>`
  border-radius: ${props => {
    return setValueThroughPriority(
      props.theme.card.borderRadius,
      DEFAULT_BORDER_RADIUS,
    );
  }};

  height: ${props => props.height};
  width: ${props => props.width};
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: auto;
  background: ${({ theme }) => {
    return theme.backgroundColors.card;
  }};
  border: ${props => {
    const hasBorder =
      props.hasBorder !== undefined
        ? props.hasBorder
        : setValueThroughPriority(
            props.theme.card.hasBorder,
            DEFAULT_HAS_BORDER,
          );
    const borderColor: string = setValueThroughPriority(
      props.theme.borderColors.card,
      DEFAULT_BORDER_COLOR,
    );

    return hasBorder ? css`1px solid ${borderColor}` : "none";
  }};
  box-shadow: ${props => {
    const hasBoxShadow: boolean =
      props.hasBoxShadow !== undefined
        ? props.hasBoxShadow
        : setValueThroughPriority(
            props.theme.card.hasBoxShadow,
            DEFAULT_HAS_BOX_SHADOW,
          );
    const boxShadowColor: string = setValueThroughPriority(
      props.theme.boxShadowColors.card,
      DEFAULT_BOX_SHADOW_COLOR,
    );

    return hasBoxShadow ? css`0 0 16px 0 ${boxShadowColor}` : "none";
  }};
`;

export const HeaderStyled = styled.header<HeaderStyledProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${({ theme }) => theme.textColors.heading};
  padding: ${props =>
    setValueThroughPriority(
      props.theme.card.headerPadding,
      DEFAULT_HEADER_PADDING,
    )};
  border-bottom: ${props => {
    const hasHeaderBorder: boolean =
      props.hasHeaderBorder !== undefined
        ? props.hasHeaderBorder
        : setValueThroughPriority(
            props.theme.card.hasHeaderBorder,
            DEFAULT_HAS_HEADER_BORDER,
          );
    const borderColor: string = setValueThroughPriority(
      props.theme.borderColors.card,
      DEFAULT_BORDER_COLOR,
    );
    return hasHeaderBorder ? css`1px solid ${borderColor}` : "none";
  }};

  .header-title {
    display: flex;
    justify-content: space-between;

    .header-title-text {
      text-transform: uppercase;
      font-size: 14px;
      font-family: Open Sans, sans-serif;

      .header-subtitle {
        padding: 0 4px;
      }
    }
  }

  .header-actions {
    height: 34px;
  }
`;

export const BodyStyled = styled.div`
  flex: 1;
  max-width: 100%;
  min-height: 0;
  padding: ${props =>
    setValueThroughPriority(
      props.theme.card.bodyPadding,
      DEFAULT_BODY_PADDING,
    )};
`;
